.likeContainer {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
    transition: opacity 0.2s, fill 0.2s, stroke 0.2s;
    fill: #fdfdfd;
    stroke: #ccc;

    &:hover {
      stroke: #FF4D4FFF;
    }
  }
}

.likedContainer {
  & svg {
    opacity: 1;
    fill: #FF4D4FFF;
    stroke: #FF4D4FFF;
  }
}
