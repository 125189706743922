.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.title {
  margin-bottom: 16px;
}

.productsContainer {
  width: 100%;
  margin-left: 350px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) );
  gap: 20px;

  & > div {
    max-width: 600px;
  }
}

.noDataMessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
