.header {
  width: 100%;
  height: var(--header_height);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: var(--color_block_background);
  border-bottom: 1px solid var(--color_border);
}

.nav {
  width: 100%;
  height: 100%;
  //max-width: var(--max-width);
  display: flex;
  align-items: center;
  // padding: 0 24px;
  // margin: 0 auto;
}

.leftHeader,
.rightHeader {
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
}

.rightHeader {
  margin-left: auto;
  margin-right: 40px;
}

.sellerFirmAvatarContainer {
  width: 60px;
  margin-right: 20px;
}

.sellerFirmAvatar {
  width: 40px;
  height: 40px;
  background-color: var(--color_primary);
  border-radius: 0px 20px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: width 0.5s ease;
  cursor: pointer;
}

.sellerFirmAvatar:hover {
  width: 60px;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  // margin-right: 32px;
  object-fit: contain;
}

.link {
  height: 100%;
  display: flex;
  align-items: center;
  // padding: 0 20px;
  font-size: 18px;
  // border-radius: 6px;
  text-decoration: none;
  // margin: 0 5px;
  border-bottom: 2px var(--color_background) solid;
  color: var(--color_text);
  font-weight: 500;
  transition: all 0.3s;
}

.activeLink,
.link:hover {
  color: var(--color_primary);
  border-bottom: 2px var(--color_primary) solid;
  // background: var(--color_primary);
  // color: var(--color_primary_text);
}

.activeLink {
  font-weight: 600;
}

.shoppingCartBtn {
  height: 24px;
  display: none;
  cursor: pointer;
  text-decoration: none;

  & > svg {
    height: 100%;
  }

  @media (min-width: 550px) {
    display: flex;
  }
}

.profileBtnContainer {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 24px;
  position: relative;
}

.profileBtn {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: none;
  border: none;

  font-size: 10px;

  cursor: pointer;
}

.avatar {
  width: 36px;
  height: 36px;
  object-fit: cover;
  // margin-right: 8px;

  background: var(--color_background);
  border-radius: 20px;
}

.profileBtnBox {
  height: 100%;
  display: none;

  @media (min-width: 550px) {
    display: flex;
    width: 100%;
  }
}

.links {
  display: none;
  @media (min-width: 550px) {
    display: flex;
    height: 100%;
    gap: 40px;
  }
}

.hamburgerMenuBox {
  display: flex;
  width: 100%;

  @media (min-width: 550px) {
    display: none;
  }
}

.profileBtnMenu {
  width: 260px;
  display: flex;
  flex-direction: column;
  text-align: right;
  overflow: hidden;

  position: absolute;
  bottom: 0;
  right: -8px;
  transform: translateY(100%);
  z-index: 1;

  background: var(--color_block_background);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  & > :nth-child(n) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    font-size: 18px;
    text-decoration: none;

    & > :first-child {
      width: 22px;
      height: 22px;
      object-fit: contain;
      object-position: center;
      margin-right: 16px;
    }

    &:hover {
      color: var(--color_primary);
    }
  }
}

.avatarInMenuContainer {
  width: 34px !important;
  height: 34px !important;
  overflow: hidden;
  margin-left: -4px;

  border-radius: 20px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.hamburgerLines {
  margin-left: auto;
  height: 36px;
  width: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 8px;
  padding-top: 8px;

  background: none;
  border: none;
}

.line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: var(--color_primary);
  opacity: 0.8;
}

.line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.line2 {
  transition: transform 0.2s ease-in-out;
}

.line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.openLine1 {
  transform: rotate(45deg);
}

.openLine2 {
  transform: scaleY(0);
}

.openLine3 {
  transform: rotate(-45deg);
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.arrow {
  fill: var(--color_text);
  transform: rotate(180deg);
  transition: transform 0.3s;
  opacity: 0.6;
}

.activeArrow {
  transform: rotate(0deg);
}

.logout {
  border-top: 2px solid var(--color_border);
  color: var(--color_danger);

  &:hover {
    color: var(--color_danger) !important;
  }
}
