.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}


.vlogContainer {
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: var(--border);
  border-radius: var(--border-radius);
}

.vlogTitle {
  
  font-size: 20px;
  padding: 16px;
}

.containerVideo {
  height: 400px;
}