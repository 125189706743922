.select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px 10px;

    border: 1px solid var(--color_placeholder);
    border-radius: 4px;
}
.arrow {
    fill: var(--color_placeholder);
    transform: rotate(180deg);
    transition: transform 0.3s;
}
.placeholder {
    color: var(--color_placeholder);
}
.selectOpen {
    border: 1px solid var(--color_primary);
    & > .arrow {
        fill: var(--color_text);
        transform: rotate(0deg);
    }
}

.optionsContainer {
    position: absolute;
    z-index: 1;

    background-color: var(--color_background);
    width: 100%;
    margin-top: 5px;
    border: 1px solid var(--color_primary);
    border-radius: 4px;
    padding: 10px;

    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.visibleOptions {
    display: flex;
}
.option {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.option:hover,
.selectedOption {
    & > p {
        color: var(--color_primary);
    }
}
.selectedOption {
    & > p {
        font-weight: 600;
    }
}
.check {
    stroke: var(--color_primary);
    width: 15px;
    height: 15px;
}

.valuesContainer {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    max-height: 40px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-moz-viewport-scroll {
        width: 0;
        height: 0;
    }
}
.valueContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid var(--color_placeholder);
    & > svg {
        width: 10px;
        height: 10px;
        stroke: var(--color_text);
    }
    & > p {
        font-size: 14px;
    }
}
