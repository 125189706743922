.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    & > h1 {
        font-size: 38px;
        width: 100%;
        text-align: center;
    }
}

.col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    & > p {
        width: 70%;
        text-align: center;
        font-size: 28px;
    }
}
.row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 40px;
    & > p {
        font-size: 28px;
    }
}
.col,
.row {
    &>img{
        width: 100%;
    }
}