.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0.6);

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}
.visible {
  visibility: visible;
  opacity: 1;
}
.container {
  background-color: var(--color_background);
  padding: 20px;
  // min-width: 40%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 0 20px 20px;
  border-bottom: var(--border);
}

.content {
  padding: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -20px;
  padding: 20px 20px 0;
  border-top: var(--border);
}
