.container {
  width: 300px;
  height: 300px;
  overflow: hidden;

  position: fixed;
  z-index: 1;

  background: black;
  border: var(--border);
  border-radius: 6px;
  cursor: grab;
}

.adviserAvatar {
  width: 80px;
  height: 80px;
  object-fit: cover;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  animation: 0.8s infinite alternate linear pulse;

  border-radius: 100px;
  border: 1px solid white;
}

@keyframes pulse {
  from {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0;
  left: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}

.btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 100px;
  cursor: pointer;

  & svg{
    width: 24px;
    height: 24px;
  }
}

.endCallBtn {
  background: var(--color_danger);

  & svg{
    fill: white;
  }
}

.video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.localVideo {
  width: 20%;
  height: 20%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 6px;

  transform: scale(-1, 1);
}

.error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}