.page {
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "t"
    "m"
    "a";

  & > h1 {
    width: 100%;
    text-align: center;
    color: var(--color_placeholder);
  }
}
