.container {
  width: 100%;
}

.title {
  margin-bottom: 32px;
  text-align: center;
}

.firms {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  //grid-template-rows: repeat(auto-fill, mi);
  grid-auto-rows: min-content;
  grid-gap: 64px;
}

.firm {
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;

  background: none;
  border: none;
  cursor: pointer;

  filter: grayscale(100%);
  opacity: 0.2;
}

.firmName {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.avatar {
  width: 200px;
  height: 200px;
  object-position: center;
  object-fit: contain;
}

.firm:hover, .activeFirm {
    filter: none;
    opacity: 1;
}
