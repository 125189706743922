.container {
  display: flex;
  padding: 6px;
  position: relative;

  // background-color: var(--color_shade_background);
  border: var(--border);
  border-radius: 4px;

  font-size: 16px;
}

.container:hover,
.focus {
  box-shadow: 0 0 4px var(--color_border);
}

.containerError {
  border-color: var(--color_danger) !important;
}

.input {
  width: 100%;
  border: none;
  // background-color: var(--color_shade_background);
  background: 0;

  &:focus {
    outline: none;
  }
}

.prefix {
  margin-right: 6px;
  cursor: default;
  pointer-events: none;
}

.anotherValue {
  margin-left: 6px;
  cursor: default;
  // pointer-events: none;
  font-size: 14px;
  color: var(--color_confirmation);
  cursor: pointer;
  white-space: nowrap;
}

.error {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  font-size: 14px;
  color: var(--color_danger);
}