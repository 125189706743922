.container {
    position: fixed;
    z-index: 1;
    background-color: var(--color_block_background);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    //border: 1px solid var(--color_shade_background);
    border-radius: 4px;
    padding: 20px;

    width: 310px;

    & > h3 {
        width: 100%;
        text-align: center;
    }
}
.block {
    width: 100%;
}
.labelInput {
    display: flex;
    align-items: center;
    gap: 10px;
}
.block > .subTitle {
    margin-bottom: 10px;
}
.subTitle {
    color: var(--color_placeholder);
    font-weight: 600;
}
