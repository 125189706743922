.row {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 20px;

    & p {
        width: 100% !important;
    }
}

.img {
    width: 100%;
    height: 100%;

    & > img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: contain;
    }
}

.text {
    width: 100%;
    height: max-content;

    & > p {
        width: 80%;
        margin: 0 auto;
    }
}
