.container {
  width: 100%;
}

.title {
  margin-bottom: 16px;
}

.productsContainer {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
