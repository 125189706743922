.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
}

.sendBtn {
  margin-bottom: 24px;
  font-size: 22px;
}

.message {
  text-align: center;
  opacity: 0.5;
}