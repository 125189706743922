.main {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 40px;
}
.head {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    & > img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: contain;
    }
}

.description {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 10px;
}
