.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 16px;
    // box-shadow: 0px 0px 1px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 16%);
  }
}

.simulateBtn {
  display: block;
  margin-left: auto;
}
