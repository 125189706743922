.page {
  position: relative;
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "m"
    "p"
    "a";

  // @media (min-width: 1000px) {
  //   grid-auto-rows: max-content;
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-areas:
  //     "m m"
  //     "p a";
  // }

  @media (min-width: 1200px) {
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: max-content;
    grid-template-areas:
      "m p"
      "a a";
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;

  background: var(--color_background);
  border: var(--border);
  border-radius: 6px;
  grid-area: p;
}

.formField {
  width: 100%;
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  margin-bottom: 32px;

  & > :last-child {
    width: 100%;
    max-width: unset !important;
  }
}

.threeSelect {
  max-height: 100px;
}
