.container {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.threeSelect {
  max-height: 80px;
  max-width: 500px;
}

// .container {
//   position: relative;
//   width: 90%;
//   height: 80%;
//   max-width: var(--max-width);
//   display: flex;
//   flex-direction: column;
//   padding: 16px 16px 8px 16px;
//   margin-top: auto;
//   background: var(--color_background);

//   border-radius: 4px 4px 0 0;
// }

// .openModal {
//   display: flex;
//   animation-duration: 0.5s;
//   animation-name: openModal;

//   @keyframes openModal {
//     from {
//       height: 0%;
//     }
//     to {
//       height: 80%;
//     }
//   }
// }

// .closeModal {
//   display: none;
//   animation-duration: 0.5s;
//   animation-name: test;

//   @keyframes closeModal {
//     from {
//       height: 80%;
//     }

//     to {
//       height: 0%;
//     }
//   }
// }

.titleContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border);
  // -webkit-mask-image: linear-gradient(rgba(207, 202, 202, 0.743) 0%,
  //     rgb(193, 183, 183) 30px,
  //     rgb(205, 203, 203) calc(100% - 50px),
  //     rgba(230, 226, 226, 0) 100%);

  // -webkit-mask-image: linear-gradient(rgba(207, 202, 202, 0.743) 0%,
  // rgb(193, 183, 183) 10px,
  // rgb(205, 203, 203) calc(100% - 10px),
  // rgba(230, 226, 226, 0) 100%);
}

.categoriesContainer {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-moz-viewport-scroll {
    width: 0;
    height: 0;
  }
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  cursor: pointer;
  // border: var(--border);
}

.productContainer {
  display: flex;
  height: 60px;
  cursor: pointer;
  border-bottom: var(--border);
  align-items: center;
  // border-bottom: none;
}

.productNameContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.selectedProduct {
  background-color: #69f97438;
}

.categoryContent {
  display: flex;
  border-bottom: var(--border);
  padding: 10px 0;
}

.arrowContainer {
  height: 24px;
  margin-left: auto;

  & > svg {
    fill: var(--color_border);
    height: 100%;
    transform: rotate(-90deg);
    transition: transform 0.2s linear;
  }
}

.openProducts {
  & > svg {
    transform: rotate(-90deg) scale(-1, 1);
  }
}

.addBtn {
  height: max-content;
}

.noProducts {
  width: 100%;
  text-align: center;
  padding: 16px;
}
