.container {
    display: flex;
    align-items: center;
    gap: 20px;
}
.gender {
    display: flex;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    & > span {
        width: 20px;
        height: 20px;

        border: 1px solid var(--color_placeholder);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            display: none;
            width: 14px;
            height: 10px;
            stroke: var(--color_background);
        }
    }
    &:hover {
        & > span {
            border-color: var(--color_primary);
        }
    }
}

.active {
    & > span {
        background-color: var(--color_confirmation);
        border-color: var(--color_confirmation);
        & > svg {
            display: block;
        }
    }
    &:hover {
        & > span {
            border-color: var(--color_confirmation);
        }
    }
}
