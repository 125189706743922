.footer {
  height: 68px;
  background: var(--color_block_background);
  box-shadow: 0 0 16px -6px var(--color_shade_background);
}

.content {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
}

.sslSecureImg {
  width: 110px;
}
