.commentsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background-color: var(--color_block_background);
  color: var(--color_text);
}

.commentContainer {
  display: flex;
  padding: 10px;
  border-bottom: var(--border);
  align-items: center;
}

.commentContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.edit_delContainer {
  position: relative;
  margin-left: auto;
}

.popConfirm {
  left: -360px;
}

.popConfirmBtnContainer {
  display: flex;
  justify-content: space-between;
}

.userNameContainer {
  display: flex;
  margin-bottom: 10px;
  align-items: baseline;
}

.username {
  font-size: 20px;
}

.reply {
  color: var(--color_link);
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.textareaContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.textareaComment {
  width: 100%;
  max-height: 150px;
  margin-right: 20px;
  padding: 16px;
  background-color: var(--color_block_background);
  color: var(--color_text);
  border-radius: 4px;
  resize: vertical;

  &:focus {
    outline: none;
  }
}

.addNewCommentBtn {
  margin-left: auto;
}
