.page {
  width: 100%;
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "m"
    "g"
    "a";

  @media (min-width: 1160px) {
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: max-content;
    grid-template-areas:
      "m g"
      "a a";
  }
}

.block {
  padding: 24px;
  // z-index: 0;

  background: var(--color_background);
  border: var(--border);
  border-radius: 6px;
}

.mainChartBlock {
  width: 100%;
  height: max-content;
  grid-area: m;
  display: flex;
  flex-direction: column;
}

.mainChart {
  // width: 100%;
  margin: 0 -24px -24px;
}

.goalsBlock {
  grid-area: g;
}

.fundsTable,
.goalsTable {
  width: 100%;
  border-collapse: collapse;

  & td {
    color: var(--color_text);
    padding: 12px 12px;
    border-top: var(--border);
    border-bottom: var(--border);
  }
}

.goalRow{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: var(--border);
}

.goalsModalContainer {
  width: 100%;
  min-width:300px;
  display: flex;
  flex-direction: column;
  // padding: 16px;
  border-radius: 4px;
  background: var(--color_background);
}

.goalsInputContainer {
  margin-bottom: 16px;
}

.error {
  color: var(--color_danger);
}

.goalThreeDotsPopup {
  position: absolute;
  top: -40px;
  right: 15px;
  width: 100px;
  background-color: var(--color_background);
  z-index: 1;
  transform: translateY(100%);
  overflow: hidden;

  background: var(--color_background);
  border: var(--border);
  border-radius: 4px;

  & > button {
    width: 100%;
    padding: 6px 12px;
    text-align: left;

    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background: var(--color_primary);
      color: var(--color_primary_text);
    }
  }
}

.ghostBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.historyBlock {
  grid-area: h;
}

.assetsBlock,
.assetBlockContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: a;
  padding: 0 24px 24px;

  @media (min-width: 700px) {
    align-items: unset;
    // flex-direction: row;
  }
}

.fundsTable {
  width: 100%;
  min-width: 300px;
  height: max-content;
}

.addNewProduct {
  margin-right: 20px;
  min-width: 400px;
}

.addProductPopConfirm {
  right: 70px;
  bottom: 40px;
}

.addProductPopConfirmBtnContainer {
  display: flex;
  justify-content: space-between;
}

.availableCashInput {
  margin-left: 6px;
}

.changeAddedCashContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.changeAddedCashContent {
  & > h1,
  h3 {
    color: var(--color_text);
    margin-bottom: 10px;
  }

  & > h1 {
    margin-bottom: 40px;
  }
}

.stickyContent {
  align-items: center;
  padding: 24px 24px 0;
  margin: 0 -24px;

  position: sticky;
  top: 0;
  z-index: 1;

  // background-color: var(--color_background);
  background: var(--color_background);
  border-bottom: var(--border);
  // border: var(--border);
}

.selectPortfolioContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.switchContainer {
  display: flex;
  margin-left: auto;
}

.switch {
  margin: 0 10px;
}

.shareButton {
  display: flex;
  align-items: center;
  gap: 10px;
  & > p {
    color: var(--color_primary_text);
  }
  & > svg {
    width: 25px;
    height: 25px;
    fill: var(--color_primary_text);
  }
}
