*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Default theme colors */
  --color_primary: #0176c6;
  --color_primary_text: #fff;
  --color_background: #f0f0f0;
  --color_block_background: #fff;
  --color_shade_background: #f7f7f7;
  --color_border: rgb(213 224 255);
  --color_text: #242427;
  --color_placeholder: #8c8c91;
  --color_danger: #ff4d4f;
  --color_link: #1890ff;
  --color_confirmation: #63ac5b;
}

body {
  overflow-y: scroll;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color_background);

  --header_height: 60px;
  --footer_height: 68px;
  --max-width: 1600px;
  --border: 1px solid var(--color_border);
  --border-radius: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color_primary);
}

p,
label,
input,
input:disabled,
a,
button,
b {
  color: var(--color_text);
}

button {
  font-size: 16px;
}

.highcharts-credits {
  display: none;
  opacity: 0;
}

/* rc-slider */

.rc-slider-handle {
  border: none;
  background: var(--color_primary);
  opacity: 1;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 6px var(--color_primary) !important;
}

.rc-slider-dot-active {
  border-color: var(--color_primary);
}

.rc-slider-track {
  background: var(--color_primary);
  opacity: 0.4;
}
