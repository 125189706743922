.container {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
}

tr {
  transform: scale(1);
}

.opacityTd {
  td {
    opacity: 0.2;
  }
}

.input {
  width: 200px;

  &>input::-webkit-inner-spin-button {
    // -webkit-appearance: none;
    margin: 0;
  }
}

.popConfirmContainer {
  right: 40px;
  bottom: 50px;
}

.popConfirmBtnContainer {
  display: flex;
  justify-content: space-between;
}

.popConfirmBtn {
  padding: 8px 10px;
}

.quantityContainer {
  margin-right: 10px;
}

.quantityContainer,
.priceContainer {
  text-align: left;

  &>p {
    margin-bottom: 4px;
  }
}

.inputsConfirmationForm {
  margin-left: auto;
}

.infoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &>p {
    font-size: 20px;
    margin-right: 20px;
  }

  // background-color: red;
}