.select {
  position: relative;
}

.placeholder {
  color: var(--color_placeholder);
}

.currentValue {
  width: 100%;
  padding: 6px;
  position: relative;

  background: var(--color_background);
  border: var(--border);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
}

.showOptions {
  border-color: var(--color_primary);
}

.valueError {
  border-color: var(--color_danger) !important;
}

.arrow {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 8px;

  &>svg {
    height: 60%;
    transition: transform 0.2s linear;
    cursor: pointer;
    transform: rotate(0deg);
  }
}

.arrowOpen>svg {
  transform: rotate(-180deg);
}

.error {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  font-size: 14px;
  color: var(--color_danger);
}

.options {
  width: 140px;
  display: none;
  position: absolute;
  bottom: -5px;
  transform: translateY(100%);

  border-radius: 4px;
  background: var(--color_background);
}

.optionsOpen {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  z-index: 1;
  overflow: hidden;

  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 10px;

  outline: none;
  background: var(--color_background);
  border: none;
  border-bottom: var(--border);

  font-size: 16px;
  color: var(--color_text);
  cursor: pointer;
  text-align: left;

  &:hover {
    // color: var(--color_primary);
    background-color: var(--color_shade_background);
  }
}