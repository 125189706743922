.container {
  position: absolute;
  width: max-content;
  height: max-content;
  padding: 16px;
  // background: rgb(109, 113, 221);
  background-color: var(--color_background);
  box-shadow: 7px 7px 20px 2px var(--color_shade_background);
  border-radius: 4px;
  z-index: 2;
}