.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.head {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.option {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > span {
    width: 100%;
    height: 2px;
    background-color: var(--color_primary);

    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  & > p {
    font-size: 20px;
    margin-bottom: 10px;
  }

  &:hover {
    & > p {
      color: var(--color_primary);
    }
  }
}

.activeOption {
  & > p {
    font-weight: 600;
    color: var(--color_primary);
  }

  & > span {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.imageBlock {
  width: 100%;
  height: 500px;
  background-color: var(--color_shade_background);

  & > img {
    pointer-events: none;
    user-select: none;
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}

.descBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.1s;
  transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.1s;
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.1s;
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.1s,
  -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.1s;

  & > p {
    width: 50%;
    text-align: center;
  }
}

.leftSlide {
  & > .descBlock {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
  }
}

.rightSlide {
  & > .descBlock {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
  }
}
