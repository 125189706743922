.page {
  width: 100%;
}

.galleryWithInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.gallery {
  width: 100%;
  max-width: 600px;
  height: 600px;
  margin: 0 auto 24px;
  overflow: hidden;

  // box-shadow: 0 0 16px -4px var(--color_shade_background);

  @media (min-width: 1024px) {
    flex: 1 0 0;
    margin: 0 24px 0 0;
  }
}

.subscribeInfoBlock {
  display: flex;
  flex-direction: column;
  padding: 20px;

  // background: rgba(255, 255, 255, 0.5);
  border: var(--border);
  border-radius: 6px;

  @media (min-width: 1024px) {
    flex: 1 0 0;
  }
}

.likeBlock {
  height: max-content;
  display: flex;
  align-items: center;
  margin-left: auto;

  font-size: 14px;
  cursor: pointer;

  &>svg {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 6px;
  }
}

.liked>svg {
  fill: red;
  stroke: red;
}

.nameContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.description,
.expireDate,
.price,
.name {
  color: var(--color_text);
}

.descriptionName,
.expireDateName,
.priceName {
  font-weight: bold;
  margin-bottom: 10px;
}

.descriptionContainer,
.expireDateContainer,
.issuerFirmContainer,
.priceContainer {
  margin-bottom: 40px;
}

.issuerFirmContainer {
  display: flex;
  align-items: center;
}

.issuerAvatarContainer {
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 10px;

  & img {
    border-radius: 50%;
  }
}

.sellerProductBlock {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;

  @media (min-width: 500px) {
    flex-direction: row;
  }
}

.subscribeBtn {
  width: 160px !important;

  font-size: 20px !important;

  // margin-left: auto;
}

.unsubscribeBtn {
  color: #fff !important;
  background: var(--color_danger) !important;
}

.subscribeStatus {
  margin-left: auto;
  margin-right: 10px;

}

.sellerInfo {
  margin-bottom: 16px;
  flex: 1 0 0;

  @media (min-width: 500px) {
    margin-bottom: 0;
  }
}

.sellerAvatar {
  height: 32px;
  object-fit: contain;
  object-position: left;
}

.bigInfo {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  border: var(--border);
  border-radius: 6px;
  // box-shadow: 0 0 16px -4px var(--color_shade_background);
}

.bigInfoBlock {
  padding: 24px;
}

.description {
  line-height: 26px;
}

.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 20px !important;
  height: fit-content !important;
  margin: auto;
  filter: drop-shadow(0 0 0.75rem rgb(133, 132, 132));
  cursor: pointer;
  transition: all 0.3s linear;
}

.arrow:hover {
  transform: scale(1.1);
}

.leftArrow {
  left: 15px;
}

.rightArrow {
  right: 15px;
  transform: rotate(180deg);
}

.rightArrow:hover {
  transform: rotate(180deg) scale(1.1);
}

.fs {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 16px !important;
  height: 16px !important;
  z-index: 1;
  color: white;
}

.thanksModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  background: var(--color_background);
  border-radius: 8px;
}