.portfolioSlider {
  :nth-child(3) {
    & > span {
      bottom: -8px;
    }
  }
}

.minMaxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 12px;
    color: var(--color_placeholder);
  }
}
