.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.primary {
  background-color: var(--color_primary);
  color: var(--color_primary_text);
}

.secondary {
  background-color: unset;
  color: var(--color_primary);
  border: 1px solid var(--color_primary);
}

.tertiary {
  border-radius: unset;
  color: var(--color_primary);
  border-bottom: 2px solid var(--color_primary);
}

.danger {
  background-color: var(--color_danger);
  color: #ffffff;
}

.disabled {
  cursor: unset;
  background-color: var(--color_placeholder);
}

.loading{
cursor: default;
}

.loadingSpin{
  width:20px !important;
  height: 20px !important;
  margin-left: 10px;
}
