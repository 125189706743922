.landing {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    & > div {
        max-height: 300px;
    }
}
