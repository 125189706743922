.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.title {
  width: 100px;
  margin-bottom: 10px;
}

// .input {
//   width: 100%;
//   max-width: 200px;
//   padding: 6px 10px;
//   outline: none;

//   border: none;
//   border-radius: 4px;
//   font-size: 16px;

//   &:focus {
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//   }
// }
