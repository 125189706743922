.sliderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 8px;
}
