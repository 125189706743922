.container {
  // max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color_background);
  z-index: 2;
}

.ghostBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.content {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // padding: 16px;
}

.linkContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.linkInput {
  width: 0;
  flex: 1 0 0;
  padding: 0;
  border: none;
  background: none;
  cursor: default;
  font-size: 20px;
  margin-right: 20px;
}

.linkInput:focus {
  outline: none;
}

.copyBtn {
  margin-left: auto;
  background: var(--color_link);
}

.shareBtnContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: auto;
}
