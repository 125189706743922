.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: var(--color_block_background);
  border: var(--border);
  border-radius: var(--border-radius);

  margin-right: auto;
}

.galleryContainer {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  cursor: pointer;
}

.gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.galleryItem {
  //min-width: calc(100% - 16px) !important;
  //min-height: calc(100% - 16px) !important;
  //max-width: calc(100% - 16px) !important;
  //max-height: calc(100% - 16px) !important;
  object-fit: cover !important;
  //margin: 8px;
  border-radius: var(--border-radius);
}

.info {
  padding: 20px;
}

.like {
  width: 20px;
  height: 20px;
  transition: opacity 0.2s;

  & > svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    stroke: var(--color_placeholder);
  }
}

.shortDescriptionContainer {
  min-height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.shortDescription,
.priceName,
.price,
.name {
  margin-bottom: 10px;
  letter-spacing: 0.8px;
  color: var(--color_text);
}

.shortDescription {
  margin-bottom: 0px;
}

.name {
  //font-size: 20px;
  font-weight: bold;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
}

.shortDescription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceName {
  font-weight: bold;
  color: var(--color_placeholder);
  margin: 0;
}

.price {
  font-size: 14px;
  margin: 0;
}

.btnsContainer {
  padding: 0 20px 20px;
  display: flex;
}

.leftArrow {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 5px;
  z-index: 1;
  filter: drop-shadow(0 0 0.75rem rgb(133, 132, 132));
}

.leftArrow :hover {
  width: 40px;
}

.rightArrow {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 15px;
  z-index: 1;
  filter: drop-shadow(0 0 0.75rem rgb(133, 132, 132));

  & > img {
    transform: rotate(180deg);
  }
}

.rightArrow:hover {
  width: 40px;
}
